import React, { useEffect } from 'react'
import { FooterStatusWrapper } from './footer-styles'
import { useState } from 'react'

function FooterStatus() {
  const [serverStatus, setServerStatus] = useState({
    text: 'Checking Status...',
    active: true
  })

  useEffect(() => {
    const updateServer = async () => {
      try {
        const data = await fetch('https://bluegeekhosting.com/api/status')
        const res = await data.json()
        if (res.down_monitors > 0) {
          setServerStatus({
            text: 'System Facing Downtime',
            active: false
          })
        } else {
          setServerStatus({
            text: 'All Systems Operational',
            active: true
          })
        }
      } catch (e) {
        setServerStatus({
          text: 'Error Getting Status...',
          active: true
        })
      }
    }
    updateServer()
  }, [setServerStatus])

  return (
    <FooterStatusWrapper
      href="https://status.bluegeekhosting.com/"
      active={serverStatus.active}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span></span> {serverStatus.text}
    </FooterStatusWrapper>
  )
}

export default FooterStatus
