import styled from 'styled-components'

export const ButtonWrapper = styled.a`
  border: none;
  border-radius: 50px;
  background-color: ${({ color }) => color || 'var(--blue)'} !important;
  color: var(--cool-white) !important;
  padding: 2px 20px 4px 20px;
  display: inline-block;
  font-family: jost;
  transition: background-color 350ms ease-out;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  svg {
    color: var(--cool-white);
    display: inline-block;
    height: 9px;
    width: 9px;
    transform: rotate(45deg) scale(0.7) translateX(2px) translateY(-2px)
      translateZ(0);
    will-change: transform;
    #arrow-line {
      opacity: 0;
      transition: opacity 300ms ease;
    }
    transition: transform 300ms ease;
  }
  &:hover {
    background-color: ${({ color }) => color || 'var(--navy2)'} !important;
    svg {
      color: var(--mint);
      transform: rotate(45deg) scale(0.7) translateX(7px) translateY(-7px)
        translateZ(0);
      #arrow-line {
        opacity: 1;
      }
    }
  }
  ${({ size }) =>
    size === 'big' &&
    `
    padding: 8px 28px 8px 28px;
    font-size:1.1rem;
    svg{
      transform: rotate(45deg) scale(0.9) translateX(2px) translateY(-2px)
      translateZ(0);
    }
    &:hover{
      svg{
        transform: rotate(45deg) scale(0.9) translateX(7px) translateY(-7px)
        translateZ(0);
      }
    }
`};
  ${({ secondary }) =>
    secondary &&
    `
    background-color: #fff !important;
    color: var(--blue) !important;
    &:hover{
    color: var(--cool-white) !important;
    }
`};

  ${({ hover }) =>
    hover &&
    `
   box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
    }
`};
`
