import React, { useState, useEffect, createContext } from 'react'

export const GlobalCurrencyContext = createContext()

function GlobalContextProvider({ children }) {
  const [currencyKey, setCurrencyKey] = useState({ key: 'priceINR', curr: '₹' })

  useEffect(() => {
    const handleCurrency = async () => {
      try {
        const data = await fetch('https://ipapi.co/country/')
        const res = await data.text()
        if (res !== 'IN') setCurrencyKey({ key: 'priceUSD', curr: '$' })
      } catch (e) {
        console.log(e)
        console.log('Error while changing currency')
      }
      return
    }
    handleCurrency()
  }, [setCurrencyKey])

  return (
    <GlobalCurrencyContext.Provider value={currencyKey}>
      {children}
    </GlobalCurrencyContext.Provider>
  )
}

export default GlobalContextProvider
