import React, { useEffect } from 'react'
import Header from './header'
import TryNow from '../trynow/trynow'
import Footer from '../footer/footer'
import GlobalContextProvider from '../../context/global-context'
import { motion, AnimatePresence } from 'framer-motion'

import './layout.scss'

// import Promo from '../promos/promos'

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
    y: -15
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration: duration }
  }
}

function Layout({ children, location, pageContext }) {
  // Logging @damnitrahul Details
  useEffect(() => {
    console.log(
      '%c👋 Oh, Hi there, Curious web lover!',
      'color: #d74034; font-size:24px;'
    )
    console.log(
      `%c  ✨ Created by @damnitrahul. Like what you see? We could work together!
    
  📬 Find me here: https://twitter.com/damnitrahul
  
  Bonus meme: https://i.imgur.com/rBRJGlv.png
  `,
      'font-size:16px'
    )
  }, [])

  // Return Login Page without Header and Footer
  if (pageContext && pageContext.layout === 'login')
    return (
      <GlobalContextProvider>
        {/* <Promo /> */}
        <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </GlobalContextProvider>
    )
  // Return All Pages with Header and Footer
  return (
    <GlobalContextProvider>
      {/* <Promo /> */}
      <Header />
      <AnimatePresence>
        <motion.div
          key={location && location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <TryNow />
      <Footer />
    </GlobalContextProvider>
  )
}

export default Layout
