// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-advance-hosting-js": () => import("./../src/pages/advance-hosting.js" /* webpackChunkName: "component---src-pages-advance-hosting-js" */),
  "component---src-pages-affiliate-js": () => import("./../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-hosting-js": () => import("./../src/pages/cloud-hosting.js" /* webpackChunkName: "component---src-pages-cloud-hosting-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dedicated-hosting-js": () => import("./../src/pages/dedicated-hosting.js" /* webpackChunkName: "component---src-pages-dedicated-hosting-js" */),
  "component---src-pages-domain-js": () => import("./../src/pages/domain.js" /* webpackChunkName: "component---src-pages-domain-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-standard-hosting-js": () => import("./../src/pages/standard-hosting.js" /* webpackChunkName: "component---src-pages-standard-hosting-js" */),
  "component---src-pages-ultimate-hosting-js": () => import("./../src/pages/ultimate-hosting.js" /* webpackChunkName: "component---src-pages-ultimate-hosting-js" */),
  "component---src-templates-docs-docs-js": () => import("./../src/templates/docs/docs.js" /* webpackChunkName: "component---src-templates-docs-docs-js" */)
}

