import styled from 'styled-components'
import media from '../../utils/breakpoints'
export const MobileHeaderWrapper = styled.header`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Jost;

  nav {
    position: relative;
    p,
    a {
      display: inline-block;
      cursor: pointer;
      color: var(--blue);
      transition: color ease 100ms;
      padding: 0.4rem;
      white-space: nowrap;
      &:hover {
        color: var(--navy);
      }
    }
    p {
      color: var(--navy2);
    }
    span.open-menu {
      padding: 0.45rem 0.75rem;
      display: block;
      border-radius: 50px;
      background-color: #f9fafb;
      svg {
        width: 16px;
        height: 10px;
        display: block;
        color: var(--grey);
      }
    }
    span.close-menu {
      padding: 0.2rem;
      height: 30.38px;
      width: 30.38px;
      display: block;
      border-radius: 5px;
      margin-left: auto;
      /* width: 55px; */
      /* background-color: var(--blue); */
      svg {
        width: 24px;
        height: 24px;
        display: block;
        color: var(--cool-white);
        fill: var(--grey);
      }
    }

    div.cta-area {
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      padding: 1rem 0;
      margin: 0 -0.7rem -0.7rem -0.7rem;
      a {
        display: block;
        padding: 2px 20px 4px 20px;
        margin: 0 auto;
      }
    }
    ul.sub-menu-mobile {
      position: absolute;
      top: -15px;
      right: 0;
      border: rgba(0, 0, 0, 0.05) solid 1px;
      list-style: none;
      width: calc(100vw - 32px);
      max-width: 320px;
      border-radius: 6px;
      padding: 1rem;
      font-size: 1.1rem;
      background-color: var(--cool-white);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
      z-index: 9;
      li {
        ul {
          list-style: none;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          font-size: 1rem;
          padding-left: 0.5rem;
        }
      }
    }
  }

  ${media.sm} {
    display: none;
  }
`
