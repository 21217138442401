import React from 'react'
import { ButtonWrapper } from './button-styles'

function Button({
  children,
  size,
  hideArrow,
  secondary,
  hover,
  as,
  href,
  to,
  disabled
}) {
  return (
    <ButtonWrapper
      as={as}
      size={size}
      secondary={secondary}
      hover={hover}
      href={href}
      to={to}
      disabled={disabled}
    >
      {children}
      {!hideArrow && (
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="arrow-angle">
            <path
              className="arrow-line"
              d="M2 1H8V7"
              stroke="currentColor"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="arrow-line">
            <path
              className="arrow-line"
              d="M1 8L8 1"
              stroke="currentColor"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      )}
    </ButtonWrapper>
  )
}

export default Button
