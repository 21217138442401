import styled from 'styled-components'
import { Container } from '../../common/common-styles'
import media from '../../utils/breakpoints'
export const HeaderWrapper = styled.header`
  width: 100%;
  font-family: Jost;
  padding: 1rem 0;
  display: none;
  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nav {
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      list-style-type: none;
      li {
        p,
        a {
          display: inline;
          padding: 1rem;
          cursor: pointer;
          color: var(--blue);
          transition: color ease 100ms;
          &:hover {
            color: var(--navy);
          }
        }
        position: relative;
        .sub-menu {
          position: absolute;
          top: 150%;
          left: 50%;
          transform: translateX(-50%);
          transform-origin: 50% 50%;
          z-index: 9;
        }
        span {
          position: absolute;
          top: 110%;
          left: 50%;
          width: 13px;
          height: 13px;
          border-radius: 3px;
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
          background: var(--cool-white);
          transform: rotate(45deg) translateY(50%);
        }
        ul {
          display: block;
          position: absolute;
          top: 150%;
          left: 50%;
          transform: translateX(-50%);
          padding: 1rem 0.5rem;
          border-radius: 5px;
          z-index: 2;
          background: var(--cool-white);
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
          :before {
            content: '';
            position: absolute;
            bottom: 98%;
            left: 50%;
            width: 13px;
            height: 13px;
            border-radius: 3px;
            background: var(--cool-white);
            transform: rotate(45deg) translateY(50%);
          }
          &.flex {
            display: flex;
          }
          div {
            padding: 0.5rem 1rem;
            h4 {
              font-family: Sen, sans-serif;
              padding-bottom: 0.5rem;
              white-space: nowrap;
            }
          }
          li {
            a {
              padding: 0.3rem 1rem;
              display: block;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  ${media.sm} {
    display: block;
  }
`
