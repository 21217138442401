import styled from 'styled-components'
import media from '../utils/breakpoints'

export const TryNowSectionWrapper = styled.div`
  padding: 1.5rem 0;
  background: url("data:image/svg+xml,%3Csvg width='1258' height='303' viewBox='0 0 1258 303' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 135.848C25.3333 198.515 128 276.648 352 87.8479C576 -100.952 465.333 65.1813 382 171.848C260 199.848 170 334 334 257.848C471.333 185.181 745.2 37.4479 758 75.8479C774 123.848 601 153.848 607 183.848C613 213.848 625 335 733 283C841 231 1032 -46.1521 996 75.8479C960 197.848 895 285 963 299C1017.4 310.2 1152 263.181 1256 183.848' stroke='%23F84F77' stroke-width='4'/%3E%3C/svg%3E%0A")
    center center / contain space;
  ${media.md} {
    padding: 3rem 0;
  }
`
export const TryNowInnerWrapper = styled.div`
  border-radius: 10px;
  background: var(--cool-white);
  padding: 2rem;
  box-shadow: 0 10px 42px -3px rgba(0, 0, 0, 0.1),
    0 4px 18px -2px rgba(0, 0, 0, 0.05);
  text-align: center;
  p {
    font-size: 1.2rem;
    padding: 1rem 0;
  }

  ${media.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    p {
      padding-bottom: 0;
    }
    a {
      white-space: nowrap;
    }
  }
`
