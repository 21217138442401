import React from 'react'
import MobileNav from '../navbar/mobile/navbar-mobile'
import DesktopNav from '../navbar/desktop/navbar-desktop'

function Header() {
  return (
    <>
      <DesktopNav /> <MobileNav />
    </>
  )
}

export default Header
