import React, { useEffect, useState } from 'react'
import { HeaderWrapper } from './navbar-desktop-styles'
import { Container } from '../../common/common-styles'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { motion } from 'framer-motion'
import Button from '../../common/cta-button/button'
import { globalHistory } from '@reach/router'

const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    y: 0,
    transition: {
      duration: 0.3
    },
    display: 'block'
  },
  exit: {
    opacity: 0,
    rotateX: -20,
    y: -15,
    transition: {
      duration: 0.3,
      delay: 0.2
    },
    transitionEnd: {
      display: 'none'
    }
  }
}

function DesktopNav() {
  const [hostingHover, setHostingHover] = useState(false)
  const [moreHover, setMoreHover] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "brand/logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const openHostingMenu = () => {
    setHostingHover(true)
  }
  const openMoreHover = () => {
    setMoreHover(true)
  }
  const closeHostingMenu = () => {
    setHostingHover(false)
  }
  const closeMoreHover = () => {
    setMoreHover(false)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        if (moreHover) closeMoreHover()
        if (hostingHover) closeHostingMenu()
      }
    })
  })

  return (
    <HeaderWrapper>
      <Container>
        <Link to="/">
          <Image
            fixed={data.logo.childImageSharp.fixed}
            alt="Blue Geek Hosting Logo"
          />
        </Link>
        <nav>
          <ul>
            <li>
              <motion.div
                className="menu"
                onMouseEnter={openHostingMenu}
                onMouseLeave={closeHostingMenu}
              >
                <p>Hosting</p>
                <motion.div
                  className="sub-menu"
                  initial="exit"
                  animate={hostingHover ? 'enter' : 'exit'}
                  variants={subMenuAnimate}
                >
                  <span></span>
                  <ul className="flex">
                    <div>
                      <h4>Shared Hosting</h4>
                      <li>
                        <Link to="/advance-hosting">Advance Hosting</Link>
                      </li>
                      <li>
                        <Link to="/standard-hosting">Standard Hosting</Link>
                      </li>
                      <li>
                        <Link to="/ultimate-hosting">Ultimate Hosting</Link>
                      </li>
                    </div>
                    <div>
                      <h4>Dedicated Hosting</h4>
                      <li>
                        <Link to="/cloud-hosting">Cloud Hosting</Link>
                      </li>
                    </div>
                  </ul>
                </motion.div>
              </motion.div>
            </li>
            <li>
              <Link to="/domain">Domain</Link>
            </li>
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <motion.div
                className="menu-item"
                onMouseEnter={openMoreHover}
                onMouseLeave={closeMoreHover}
              >
                <p>More</p>
                <motion.div
                  className="sub-menu"
                  initial="exit"
                  animate={moreHover ? 'enter' : 'exit'}
                  variants={subMenuAnimate}
                >
                  <span></span>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/affiliate">Affiliate</Link>
                    </li>
                  </ul>
                </motion.div>
              </motion.div>
            </li>
          </ul>
        </nav>
        <Button as={Link} to="/login" className=".header-cta">
          Login
        </Button>
      </Container>
    </HeaderWrapper>
  )
}

export default DesktopNav
