const size = {
  sm: 640,
  md: 769,
  lg: 1025,
  xl: 1280
}

const media = {
  sm: `@media (min-width: ${size.sm}px)`,
  md: `@media (min-width: ${size.md}px)`,
  lg: `@media (min-width: ${size.lg}px)`,
  xl: `@media (min-width: ${size.xl}px)`
}

export default media
