import styled from 'styled-components'
import media from '../utils/breakpoints'

export const FooterWrapper = styled.footer``
export const FooterInnerWrapper = styled.div`
  margin-top: 2rem;
  border-top: 2px #f3f4f7 solid;
  div.footer-about {
    padding: 1rem 0;
    p {
      padding: 0.5rem 0;
      font-size: 0.8rem;
      span {
        display: block;
        padding: 0.1rem 0;
      }
      a {
        font-size: 0.7rem;
        color: var(--grey);
      }
    }
    div.payment-partner {
    }
  }
  div.footer-links {
    ul {
      list-style: none;
      padding: 1rem 0;
      h4 {
        padding-bottom: 0.5rem;
      }
      li {
        font-size: 0.9rem;
        a {
          color: var(--grey);
          transition: color 200ms ease;
          &:hover {
            color: var(--black);
          }
        }
      }
    }
  }
  ${media.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    div.footer-links {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: flex-start;
    }
    div.footer-about {
      flex: 1;
    }
  }
`

export const FooterStatusWrapper = styled.a`
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  background: ${({ active }) =>
    active ? '#d8fbe0' : 'rgba(253, 167, 52, 0.15)'};
  color: ${({ active }) => (active ? '#147132' : 'rgb(253, 167, 52)')};
  width: fit-content;
  font-size: 0.9rem;
  span {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    background: ${({ active }) => (active ? '#147132' : 'rgb(253, 167, 52)')};
  }
  &:hover {
    color: ${({ active }) => (active ? '#147132' : 'rgb(253, 167, 52)')};
    background: ${({ active }) =>
      active ? '#b2f8c1' : 'rgba(253, 167, 52, 0.25)'};
  }
`
