import styled from 'styled-components'
import media from '../utils/breakpoints'
export const Container = styled.div`
  max-width: ${({ width }) => width || '1080px'};
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`
export const Underline = styled.span`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
    top: 80%;
    background: url("data:image/svg+xml,%3Csvg width='116' height='6' viewBox='0 0 116 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M111.168 4.5L109.96 3.75H116L114.188 2.25H111.772H114.49L106.638 1.5L103.617 0.75L90.933 0H71.3022L57.1076 0.75H27.8124L18.148 1.5L6.06748 2.25H2.74534L0.329245 3.75C-0.27478 3.75 0.0272324 4.5 0.631257 4.5H3.04736L2.44333 5.25H8.48358L18.45 6H32.9466L52.2754 5.25H100.597L103.92 4.5H111.168Z' fill='%23F84F77'/%3E%3C/svg%3E%0A")
      top / contain no-repeat;
    background-size: 100%;
  }
`

export const TwoColumnWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  padding: 1.5rem 0;
  div.content {
    display: grid;
    place-content: center;
    font-size: 1.1rem;
    h3 {
      padding: 1rem 0 0.5rem;
    }
  }

  div.image {
    display: grid;
    place-items: center;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  ${({ reverse }) =>
    reverse &&
    `
  div.image {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  `}

  ${media.md} {
    padding: 3rem 0;
    grid-template-columns: 1fr 1fr;

    ${({ reverse }) =>
      reverse &&
      `
  div.image {
    grid-row: auto;
    grid-column: auto;
  }
  `}
  }
`
