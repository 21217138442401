import React from 'react'
import { TryNowSectionWrapper, TryNowInnerWrapper } from './trynow-styles'
import { Container } from '../common/common-styles'
import Button from '../common/cta-button/button'
function TryNow() {
  return (
    <TryNowSectionWrapper>
      <Container>
        <TryNowInnerWrapper>
          <div>
            <h2>
              Try BlueGeekHosting Today<span className="accent-pink">!</span>
            </h2>
            <p>
              <span>
                Launch your next WordPress site in minutes. 30 day money-back
                guarantee.
              </span>
            </p>
          </div>
          <Button size="big" href="https://my.bluegeekhosting.com/cart.php">
            Get Started Now
          </Button>
        </TryNowInnerWrapper>
      </Container>
    </TryNowSectionWrapper>
  )
}

export default TryNow
