import React from 'react'
import { FooterWrapper, FooterInnerWrapper } from './footer-styles'
import { Container } from '../common/common-styles'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import FooterStatus from './footer-status'

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      brandLogo: file(relativePath: { eq: "brand/logo.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentPartners: file(relativePath: { eq: "brand/payment-methods.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container>
        <FooterInnerWrapper>
          <div className="footer-about">
            <div className="brand-logo">
              <Image
                fixed={data.brandLogo.childImageSharp.fixed}
                alt="Blue Geek Hosting Logo"
              />
            </div>
            <FooterStatus />
            <p>
              <span>© 2020 BlueGeekHosting. All rights reserved.</span>
              <span>Prices are listed without VAT.</span>
              <a href="/sitemap.xml">Sitemap</a>
            </p>
            <div className="payment-partner">
              <Image
                fixed={data.paymentPartners.childImageSharp.fixed}
                alt="Payment Method Partners"
              />
            </div>
          </div>
          <div className="footer-links">
            <ul>
              <li>
                <h4>Hosting</h4>
              </li>
              <li>
                <Link to="/standard-hosting">Standard Plan</Link>
              </li>
              <li>
                <Link to="/advance-hosting">Advance Plan</Link>
              </li>
              <li>
                <Link to="/ultimate-hosting">Ultimate Plan</Link>
              </li>
              <li>
                <Link to="/cloud-hosting">Cloud Hosting</Link>
              </li>
            </ul>
            <ul>
              <li>
                <h4>Other Links</h4>
              </li>
              <li>
                <Link to="/domain">Domains</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
            <ul>
              <li>
                <h4>More Links</h4>
              </li>
              <li>
                <Link to="/affiliate">Affiliate</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>
              <li>
                <Link to="mailto:abuse@bluegeekhosting.com ">Report Abuse</Link>
              </li>
            </ul>
          </div>
        </FooterInnerWrapper>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
