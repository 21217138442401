import React, { useState, useEffect } from 'react'
import { MobileHeaderWrapper } from './navbar-mobile-styles'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import icon from '../../../images/icons/icons.svg'
import { motion } from 'framer-motion'
import Button from '../../common/cta-button/button'
import { globalHistory } from '@reach/router'

const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    x: 0,
    y: 0,
    transition: {
      duration: 0.3
    },
    display: 'block'
  },
  exit: {
    opacity: 0,
    rotateX: -10,
    x: 5,
    y: -5,
    transition: {
      duration: 0.3,
      delay: 0.2
    },
    transitionEnd: {
      display: 'none'
    }
  }
}

function MobileNav() {
  const [menu, setMenu] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "brand/logo.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const openMenu = () => {
    setMenu(true)
  }
  const closeMenu = () => {
    setMenu(false)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeMenu()
      }
    })
  })

  return (
    <MobileHeaderWrapper>
      <Link to="/">
        <Image
          fixed={data.logo.childImageSharp.fixed}
          alt="Blue Geek Hosting Logo"
        />
      </Link>
      <nav>
        <span
          onClick={openMenu}
          className="open-menu"
          tabIndex="0"
          name="Open Menu"
          onKeyPress={openMenu}
          role="button"
        >
          <svg>
            <use href={`${icon}#hamburger`} />
          </svg>
        </span>

        <motion.ul
          className="sub-menu-mobile"
          initial="exit"
          animate={menu ? 'enter' : 'exit'}
          variants={subMenuAnimate}
        >
          <div className="close-btn">
            <span
              onClick={closeMenu}
              className="close-menu"
              tabIndex="0"
              name="Close Menu"
              onKeyPress={closeMenu}
              role="button"
            >
              <svg>
                <use href={`${icon}#close`} />
              </svg>
            </span>
          </div>
          <li>
            <p> Hosting </p>
            <ul>
              <li>
                <Link to="/standard-hosting">Standard Plan</Link>
              </li>
              <li>
                <Link to="/advance-hosting">Advance Plan</Link>
              </li>
              <li>
                <Link to="/ultimate-hosting">Ultimate Plan</Link>
              </li>
            </ul>
          </li>
          <li>
            <p>Dedicated Hosting</p>
            <ul>
              <li>
                <Link to="/cloud-hosting">Cloud Hosting</Link>
              </li>
            </ul>
          </li>
          <li>
            <p>Domains</p>
            <ul>
              <li>
                <Link to="/domain">Register Domain</Link>
              </li>
            </ul>
          </li>
          <li>
            <p> More </p>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/affiliate">Affiliate</Link>
              </li>
            </ul>
          </li>
          <div className="cta-area">
            <Button as={Link} to="/login">
              Login
            </Button>
          </div>
        </motion.ul>
      </nav>
    </MobileHeaderWrapper>
  )
}

export default MobileNav
